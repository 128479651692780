import React from 'react';

const SubmitInput = ({ onChange = () => {}, text }) => (
  <input
    className="call-to-action-button py-3 ml-auto mr-auto text-center block cursor-pointer"
    data-cy="get-started-cta-button"
    onChange={onChange}
    type="Submit"
    value={text}
  />
);

export default SubmitInput;
