import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Footer, LegalDisclosure, Loading } from '../components/common';
import { VideoModal } from '../components/LandingPage';
import { storeReferrerId } from '../redux/actions/leadCaptureActions';

const LandingPageLayout = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isSaving = useSelector((state) => state.lead.isSaving);
  const showVideo = useSelector((state) => state.video.showVideo);

  useEffect(() => {
    if (history.location && history.location.search) {
      const queryString = new URLSearchParams(history.location.search);

      const referrerId = queryString.get('ref');

      dispatch(storeReferrerId({ referrerId }));
    }
  }, [dispatch, history]);

  return (
    <div>
      {showVideo && <VideoModal />}
      {isSaving && <Loading message="Saving..."/>}
      {children}
      <LegalDisclosure />
      <Footer />
    </div>
  );
};

export default LandingPageLayout;
