/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLead = /* GraphQL */ `
  query GetLead($id: ID!) {
    getLead(id: $id) {
      id
      createdAt
      email
      updatedAt
      leadReferrerId
      referrer {
        id
        createdAt
        email
        updatedAt
        leadReferrerId
      }
    }
  }
`;
export const listLeads = /* GraphQL */ `
  query ListLeads(
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        email
        updatedAt
        leadReferrerId
      }
      nextToken
    }
  }
`;
export const getLeadCount = /* GraphQL */ `
  query GetLeadCount($id: ID!) {
    getLeadCount(id: $id) {
      id
      value
      createdAt
      updatedAt
    }
  }
`;
export const listLeadCounts = /* GraphQL */ `
  query ListLeadCounts(
    $filter: ModelLeadCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeadCounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
