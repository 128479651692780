import { API } from 'aws-amplify';
import { createLead, updateLead } from '../../graphql/mutations';

export const SAVE_LEAD = 'SAVE_LEAD';
export const SAVE_LEAD_FAIL = 'SAVE_LEAD_FAIL';
export const SAVE_LEAD_SUCCESS = 'SAVE_LEAD_SUCCESS';
export const SET_SAVE_LEAD_SUCCEEDED = 'SET_SAVE_LEAD_SUCCEEDED';
export const STORE_REFERRER_ID = 'STORE_REFERRER_ID';
export const UPDATE_LEAD = 'UPDATE_LEAD';

const saveLead = () => async (dispatch, getState) => {
  dispatch({ type: SAVE_LEAD });

  const state = getState();

  const lead = state.lead.data;

  const saveOperation = lead.id ? updateLead : createLead;

  try {
    const saveResponse = await API.graphql({
      query: saveOperation,
      variables: {
        input: lead,
      },
      authMode: 'API_KEY'
    });

    dispatch({ lead: { ...lead, id: lead.id || saveResponse.data.createLead.id }, type: SAVE_LEAD_SUCCESS });
  } catch (error) {
    console.log('saveLeadFailure was called with:', error);

    dispatch({ lead, type: SAVE_LEAD_FAIL });
  }
};

const setSaveLeadSucceeded = () => ({ type: SET_SAVE_LEAD_SUCCEEDED });

const storeReferrerId = ({ referrerId }) => ({ referrerId, type: STORE_REFERRER_ID });

const updateLeadInformation = ({ propertyName, value }) => ({
  propertyName,
  type: UPDATE_LEAD,
  value,
})

export {
  saveLead,
  setSaveLeadSucceeded,
  storeReferrerId,
  updateLeadInformation as updateLead,
};
