import React from 'react';
import { FooterCallToAction } from '../../components/common';
import { Hero, SeeHowItWorks, WhyCreditGenie } from '../../components/LandingPage';

const EmailCaptureLandingPage = () => (
  <div>
    <Hero />
    <SeeHowItWorks />
    <WhyCreditGenie />
    <FooterCallToAction />
  </div>
);

export default EmailCaptureLandingPage;
