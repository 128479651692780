import React from 'react';
import CreditGenieLogo from '../../../assets/logos/creditgenie_logo_blue.svg';

const ComparisonChart = () => (
  <section className="comparison-chart sm:block hidden">
    <div className="container max-w-5xl mx-auto m-8">
      <div className="table-mt">
        <table className="table-auto m-auto">
          <thead>
            <tr>
              <th className="px-4 py-2">&nbsp;</th>
              <th className="top-border left-border right-border px-4 py-2">
                <img src={CreditGenieLogo} alt="Credit Genie Logo" />
              </th>
              <th className="header text-center uppercase py-2 bg-gray">Debt Settlement Agencies</th>
              <th className="header text-center uppercase py-2 bg-gray ml-5">Filing Bankruptcy</th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-gray-100">
              <td className="first-col-width uppercase border px-4 py-5 font-bold">Cost</td>
              <td className="left-border right-border sub-col-width border px-4 py-5 text-center">FREE</td>
              <td className="sub-col-width border px-4 py-5 text-center">20% - 25% of total debt enrolled</td>
              <td className="sub-col-width border px-4 py-5 text-center">$800 - $1,500</td>
            </tr>
            <tr>
              <td className="first-col-width uppercase border px-4 py-5 font-bold">Additional Fees</td>
              <td className="left-border right-border sub-col-width border px-4 py-5 text-center">FREE</td>
              <td className="sub-col-width border px-4 py-5 text-center">Monthly acccount manintenance and payment fees</td>
              <td className="sub-col-width border px-4 py-5 text-center">None</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="first-col-width uppercase border px-4 py-5 font-bold">Debt Resolution</td>
              <td className="bottom-border left-border right-border sub-col-width border px-4 py-5 text-center">10 minute decision</td>
              <td className="sub-col-width border px-4 py-5 text-center">40 - 48 months</td>
              <td className="sub-col-width border px-4 py-5 text-center">3 - 5 months</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
);

export default ComparisonChart;
