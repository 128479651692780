import React from 'react';
import ReactDom from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './styles/build-tailwind.css';
import './styles/index.scss';
import TagManager from 'react-gtm-module';
import App from './App';

const tagManagerArgs = { gtmId: 'GTM-WQWK9Q7' };
TagManager.initialize(tagManagerArgs);

const app = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
const root = document.getElementById('root');

ReactDom.render(app, root);
