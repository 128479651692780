import React from 'react';
import { FooterCallToAction } from '../../components/common';
import ComparisonChart from './ComparisonChart/ComparisonChart';
import { HashLink } from 'react-router-hash-link';

const FAQ = () => (
  <div>
    <div className="faq w-full">
      <div className="container inner-width m-auto py-20 text-center sm:px-0 px-2">
        <div className="sm:flex flex-none text-left">
          <div className="in-page-nav sm:w-1/4 w-full">
            <ul>
              <li>
                <HashLink smooth to="/faq#general">General</HashLink>
              </li>
              <li>
                <HashLink smooth to="/faq#service">Service</HashLink>
              </li>
              <li>
                <HashLink smooth to="/faq#comparison">Comparison</HashLink>
              </li>
              <li>
                <HashLink smooth to="/faq#income">Income</HashLink>
              </li>
              <li>
                <HashLink smooth to="/faq#bank-connection">Bank Connection</HashLink>
              </li>
              <li>
                <HashLink smooth to="/faq#jurisdictions">Jurisdiction(s)</HashLink>
              </li>
            </ul>
          </div>
          <div className="sm:w-3/4 w-full sm:mt-0 mt-4">
            <h3 className="font-bold" id="general">
              What is Credit Genie?
            </h3>
            <p className="pt-2">
              Credit Genie is a FREE service that works directly with your creditors to reduce your debt payments
              to a level you can afford to pay. More than just your credit score, Credit Genie analyzes your income,
              expenses and personal debt (such as credit cards and personal loans) to figure out your monthly payments
              and implement them with all your creditors in a combined single payment. This service is always free for
              you – no hidden fees, no hidden commitments, no future charges. You can also opt out of the Credit Genie
              service and deal directly with your creditors at any time, without any fees.
              <br/><br/>
              Credit Genie is operated by Creditly Corp., a financial technology company incorporated in Delaware.
            </p>
            <br/>
            <h3 className="font-bold" id="service">
              How is Credit Genie able to provide this service FREE of charge?
            </h3>
            <p className="pt-2">
              Because Credit Genie works directly with your creditors (credit card issuers or loan providers), we are
              able to provide this service to you, on the creditor&apos;s behalf, completely free. Other providers of debt
              relief options charge up to 25% of the total debt amount. Creditors pay Credit Genie a small monthly flat
              fee for processing your monthly payments and providing you the tools to manage your debt and keep you on
              the path to debt relief.
            </p>
            <br/>
            <h3 className="font-bold">
              When will I know what my reduced debt payments are?
            </h3>
            <p className="pt-2">
              The debt relief application on Credit Genie takes about 10 minutes to complete. Once completed, if we determine
              we can help you we will present you with a plan to lower your monthly payments. If you accept the presented
              plan, Credit Genie will then contact your creditors to try to secure the Debt Relief Plan for you. This process
              takes about 48-72 hours for us to get a response from your creditors and then notify you by email so you can
              confirm and lock in those lower payments.
            </p>
            <br/>
            <h3 className="font-bold">
              How do I pay my creditors?
            </h3>
            <p className="pt-2">
              Once your creditors have confirmed your reduced monthly payments, Credit Genie will combine the monthly payments
              owed to those creditors into a single payment. This payment will be debited from your connected checking account
              every month and sent to your creditors directly. Your creditors will pay payment processing fees to Credit Genie
              for this service. If the creditors do not approve the reduced payment, no payments will be collected from you.
            </p>
            <br/>
            <div id="comparison">
              <ComparisonChart />
            </div>
            <br/>
            <h3 className="font-bold" id="income">
              I am getting unemployment benefits right now, is this considered income?
            </h3>
            <p className="pt-2">
              YES. Unemployment benefits can be used to continue paying your debts. Credit Genie will work with your creditors
              to ensure that you are paying what you can comfortably afford based on analyzing your total income and budget.
              We understand that tough times can lead to loss of income or higher expenses, and encourage you to instantly
              notify us of any changes to your financial situation so that, along with our non-profit credit counseling partners,
              we can work with you to stay on track to eliminate your debt.
            </p>
            <br/>
            <h3 className="font-bold" id="bank-connection">
              Is it safe to connect my checking account?
            </h3>
            <ul className="list-disc pt-2">
              <li className="ml-4">
                We use Plaid, the nation&apos;s largest bank connection provider, to securely link your accounts.
              </li>
              <li className="ml-4 pt-2">
                Security of your personal information and data are top priorities at Plaid.
              </li>
              <li className="ml-4 pt-2">
                Credit Genie only has read access to the accounts you link and we never store your login credentials.
              </li>
              <li className="ml-4 pt-2">
                If you decide not to link your account, you can still speak to a counselor (don&apos;t worry — it won&apos;t affect your credit score).
              </li>
            </ul>
            <br/>
            <h3 className="font-bold" id="jurisdictions">
              What states is Credit Genie going to be operating in?
            </h3>
            <div className="flex">
              <div className="w-1/4 pt-2">
                <ul>
                  <li>Alabama</li>
                  <li>Alaska</li>
                  <li>Arizona</li>
                  <li>Arkansas</li>
                  <li>California</li>
                  <li>Colorado</li>
                  <li>Connecticut</li>
                  <li>Delaware</li>
                  <li>Florida</li>
                  <li>Georgia</li>
                  <li>Idaho</li>
                  <li>Illinois</li>
                </ul>
              </div>
              <div className="w-1/4 pt-2">
                <ul>
                  <li>Indiana</li>
                  <li>Iowa</li>
                  <li>Kansas</li>
                  <li>Kentucky</li>
                  <li>Maine</li>
                  <li>Maryland</li>
                  <li>Michigan</li>
                  <li>Minnesota</li>
                  <li>Mississippi</li>
                  <li>Missouri</li>
                  <li>Montana</li>
                  <li>Nebraska</li>
                </ul>
              </div>
              <div className="w-1/4 pt-2">
                <ul>
                  <li>Nevada</li>
                  <li>New Hampshire</li>
                  <li>New Mexico</li>
                  <li>New York</li>
                  <li>North Dakota</li>
                  <li>Ohio</li>
                  <li>Oklahoma</li>
                  <li>Oregon</li>
                  <li>Pennsylvania</li>
                  <li>Rhode Island</li>
                  <li>South Carolina</li>
                  <li>South Dakota</li>
                </ul>
              </div>
              <div className="w-1/4 pt-2">
                <ul>
                  <li>Tennessee</li>
                  <li>Texas</li>
                  <li>Utah</li>
                  <li>Vermont</li>
                  <li>Washington</li>
                  <li>West Virginia</li>
                  <li>Wisconsin</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterCallToAction />
  </div>
);

export default FAQ;
