/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://enqjcmlphncrxlqgg5an354a2m.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-6bo5cxwif5hjljc72iyz5demlm",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_jrzIeBHEu",
    "aws_user_pools_web_client_id": "40ifsbd3dpl69u6savsnnsvtej",
    "oauth": {}
};


export default awsmobile;
