import {
  SAVE_LEAD,
  SAVE_LEAD_FAIL,
  SAVE_LEAD_SUCCESS,
  SET_SAVE_LEAD_SUCCEEDED,
  STORE_REFERRER_ID,
  UPDATE_LEAD,
} from '../actions/leadCaptureActions';

const initialState = {
  data: {
    id: undefined,

    email: undefined,
    state: undefined,

    leadReferrerId: undefined,
    referrer: undefined,
  },
  isSaving: undefined,
  saveFailed: undefined,
  saveSuccess: undefined,
};

const leadCaptureReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LEAD:
      return {
        ...state, isSaving: true, saveFailed: undefined, saveSuccess: undefined,
      };

    case SAVE_LEAD_FAIL:
      return {
        ...state, data: action.lead, isSaving: false, saveFailed: true, saveSuccess: false,
      };

    case SAVE_LEAD_SUCCESS: {
      return {
        ...state,
        data: { ...state.data, ...action.lead },
        isSaving: false,
        saveFailed: false,
        saveSuccess: true,
      };
    }

    case SET_SAVE_LEAD_SUCCEEDED:
      return { ...state, saveSuccess: true };

    case STORE_REFERRER_ID:
      return { ...state, data: { ...state.data, leadReferrerId: action.referrerId } };

    case UPDATE_LEAD:
      return { ...state, data: { ...state.data,  [action.propertyName]: action.value } };

    default:
      return state;
  }
};

export default leadCaptureReducer;
