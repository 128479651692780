import React from 'react';
import { Link } from 'react-router-dom';

const LegalDisclosure = () => (
  <div className="w-full">
    <div className="legal-disclosure container m-auto py-10 text-left xl:px-0 px-2">
      <p className="font-bold">Legal Notice and Disclosures</p>
      <p>
        Credit Genie&apos;s free debt resolution services will be available only via web or mobile devices through completing our online offer.
      </p>
      <p>
        Credit Genie currently operates in the following <Link className="color-blue" to="/faq" target="_blank">jurisdiction(s)</Link>. This is not an offer, solicitation of an offer, or advice to resolve
        your personal debt in any jurisdiction where Credit Genie is not registered or licensed. Getting "early access" to Credit Genie is defined as
        signing up with a valid email address for a spot in Credit Genie&apos;s waitlist queue. Residents of jurisdictions where Credit Genie is
        registered or licensed will be notified first, based on their current position in line and will be given an invitation to access Credit Genie&apos;s
        debt resolution application process. Early access to Credit Genie&apos;s waitlist queue should in no way be construed as confirmation that
        the applicant will be approved for debt resolution services. If approved Credit Genie cannot guarantee the resolution of any of the
        applicant&apos;s debts.
      </p>
      <p>
        Credit Genie communications and communications emanating from its social media community are for informational purposes only and are not
        intended as an offer or solicitation for the offer of any debt resolution services or outcome or as an official confirmation of any services
        or transactions. The Credit Genie website provides its users links to social media sites and email. The linked social media and email messages
        are pre-populated. However, these messages can be deleted or edited by Credit Genie users, who are under no obligation to send any pre-populated
        messages. All services, data and other information available through Credit Genie are not warranted as to completeness or accuracy and are subject
        to change without notice.
      </p>
      <p>
        Note that certain Credit Genie product features listed are currently in development and will be available soon.
      </p>
    </div>
  </div>
);

export default LegalDisclosure;
