import Amplify from 'aws-amplify';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import React from 'react';
import thunk from 'redux-thunk';
import awsconfig from './aws-exports';
import combinedReducers from './redux/reducers/reducers';
import LandingPageLayout from './layout/LandingPageLayout';
import { loadState, saveState } from './helpers/stateHelper';
import { AlternativeEmailCaptureLandingPage, EmailCaptureLandingPage, EmailCaptureSuccess, FAQ, PrivacyPolicy, TermsAndConditions, VideoSalesLetterLandingPage } from './pages';
import SubPageLayout from './layout/SubPageLayout';

Amplify.configure(awsconfig);

const persistedState = loadState();

const reduxStore = createStore(combinedReducers, persistedState, applyMiddleware(thunk));

reduxStore.subscribe(() => {
  saveState({ state: reduxStore.getState() });
});

const App = () => (
  <Provider store={reduxStore}>
    <Router>
      <div>
        <Switch>
          <Route
            exact
            path={
              [
                '/',
              ]
            }
            >
              <LandingPageLayout>
                <Route path="/" exact component={EmailCaptureLandingPage} />
              </LandingPageLayout>
            </Route>
        </Switch>
        <Switch>
          <Route
            exact
            path={
              [
                '/faq',
                '/success',
                '/terms-and-conditions',
                '/privacy-policy',
                '/presentation',
                '/debt-relief',
              ]
            }
            >
              <SubPageLayout>
                <Route path="/faq" component={FAQ} />
                <Route path="/success" component={EmailCaptureSuccess} />
                <Route path="/terms-and-conditions" component={TermsAndConditions} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/presentation" component={VideoSalesLetterLandingPage} />
                <Route path="/debt-relief" component={AlternativeEmailCaptureLandingPage} />
              </SubPageLayout>
            </Route>
        </Switch>
      </div>
    </Router>
  </Provider>
);

export default App;
