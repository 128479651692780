/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateLead = /* GraphQL */ `
  mutation UpdateLead(
    $input: UpdateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    updateLead(input: $input, condition: $condition) {
      id
      createdAt
      email
      updatedAt
      leadReferrerId
      referrer {
        id
        createdAt
        email
        updatedAt
        leadReferrerId
      }
    }
  }
`;
export const deleteLead = /* GraphQL */ `
  mutation DeleteLead(
    $input: DeleteLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    deleteLead(input: $input, condition: $condition) {
      id
      createdAt
      email
      updatedAt
      leadReferrerId
      referrer {
        id
        createdAt
        email
        updatedAt
        leadReferrerId
      }
    }
  }
`;
export const deleteLeadCount = /* GraphQL */ `
  mutation DeleteLeadCount(
    $input: DeleteLeadCountInput!
    $condition: ModelLeadCountConditionInput
  ) {
    deleteLeadCount(input: $input, condition: $condition) {
      id
      value
      createdAt
      updatedAt
    }
  }
`;
export const createLead = /* GraphQL */ `
  mutation CreateLead(
    $input: CreateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    createLead(input: $input, condition: $condition) {
      id
      createdAt
      email
      updatedAt
      leadReferrerId
      referrer {
        id
        createdAt
        email
        updatedAt
        leadReferrerId
      }
    }
  }
`;
export const createLeadCount = /* GraphQL */ `
  mutation CreateLeadCount(
    $input: CreateLeadCountInput!
    $condition: ModelLeadCountConditionInput
  ) {
    createLeadCount(input: $input, condition: $condition) {
      id
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateLeadCount = /* GraphQL */ `
  mutation UpdateLeadCount(
    $input: UpdateLeadCountInput!
    $condition: ModelLeadCountConditionInput
  ) {
    updateLeadCount(input: $input, condition: $condition) {
      id
      value
      createdAt
      updatedAt
    }
  }
`;
