import React from 'react';
import { useSelector } from 'react-redux';
import { TrophyGraphic } from '../../components/common';
import { ShareLinks } from '../../components/LandingPage';

const EmailCaptureSuccess = () => {
  const leadCount = useSelector((state) => state.leadCount.data.value);

  return (
    <div className="email-capture-success w-full bg-white">
      <div className="container inner-width m-auto sm:pt-10 pt-4 pb-20 text-center sm:px-0 px-2">
        <h1 className="font-bold pb-4">
          Congratulations!
        </h1>
        <TrophyGraphic />
        <h1 className="font-bold py-4">
          We have emailed you an invitation to get started. Check your email NOW.
        </h1>
        <div className="reservation-number">
          <p className="waiting-list-number pt-12">You are <span className="color-blue font-bold">#{leadCount}</span> to get debt relief from Credit Genie.</p>
        </div>
        <ShareLinks />
      </div>
    </div>
  );
};

export default EmailCaptureSuccess;
