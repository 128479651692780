import React from 'react';
import seeHowItWorksPhone from '../../../assets/images/how-it-works-phone_graphic.png';
import { HashLink } from 'react-router-hash-link';

const SeeHowItWorks = () => (
  <div className="w-full bg-dark-blue">
    <div className="container inner-width sm:flex flex-none m-auto sm:py-20 py-10 sm:px-0 px-2">
      <div className="sm:w-2/3 w-full pr-6">
        <h2 className="text-white text-right font-bold sm:pt-32">
          Say goodbye to fees and commissions for resolving your credit card bills and other debt,
          and hello to the future of becoming debt free!<sup>*</sup>
        </h2>
        <HashLink className="text-right float-right color-blue underline font-bold sm:pt-4" to="/faq#general" target="_blank">
          Learn how it works &gt;
        </HashLink>
        <p className="legal-disclosure text-white text-right mt-20">
          <sup>*</sup>Credit Genie does not guarantee any debt reduction and consumers may have other forms of debt we cannot address for them.
        </p>
      </div>
      <div className="w-1/3 sm:block hidden">
        <img src={seeHowItWorksPhone} alt="The offer presentment" />
      </div>
    </div>
  </div>
);

export default SeeHowItWorks;
