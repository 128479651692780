import React from 'react';
import { useSelector } from 'react-redux';

const EmailAddressInput = ({
  disabled = false,
  errors = {},
  id,
  label = 'Email address',
  loadFrom = 'lead',
  name = 'email',
  onBlur = () => {},
  onChange,
  placeholder = ' ',
  required = true,
  validationName,
}) => {
  const acceptableTopLevelDomains = 'biz|com|edu|gov|info|name|net|mil|org|us';
  const emailRegex = '[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+(?:[A-Z]{2}|' + `${acceptableTopLevelDomains})\\b`;
  const value = useSelector((state) => (loadFrom && name && state[loadFrom].data[name]) || '');

  const onInputBlur = (event) => {
    const { value } = event.target;

    const valueOverride = value.toLowerCase().trim();

    onBlur(event, valueOverride);
  };

  return (
    <div className="w-full m-auto">
      <div className="mb-4">
        <label htmlFor={id} className="input-container">
          <input
            className="email-capture-input"
            data-cy="email-capture-input"
            disabled={disabled}
            id={id}
            name={name}
            onBlur={onInputBlur}
            onChange={onChange}
            placeholder={placeholder}
            regex={emailRegex}
            required={required}
            type="email"
            validationname={validationName}
            value={value}
          />
          <span className="label">{label}</span>
          <span className="border" />
        </label>
      </div>
      {(validationName ? errors[validationName] === 'invalid' : errors[name] === 'invalid') && (
      <p className="validation">
        {label}
        {' '}
        is invalid
      </p>
      )}
      {(validationName ? errors[validationName] === 'required' : errors[name] === 'required') && (
      <p className="validation">
        {label}
        {' '}
        is required
      </p>
      )}
    </div>
  );
};

export default EmailAddressInput;
