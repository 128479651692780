import React from 'react';
import { HowItWorksVideo } from '../../components/common';
import { AlternativeHero, WhyCreditGenie } from '../../components/LandingPage';

const AlternativeEmailCaptureLandingPage = () => (
  <div className="alternative-email-capture-landing-page">
    <AlternativeHero />
    <HowItWorksVideo />
    <WhyCreditGenie />
  </div>
);

export default AlternativeEmailCaptureLandingPage;
