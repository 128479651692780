import React from 'react';
import FooterCallToActionButton from '../FooterCallToActionButton/FooterCallToActionButton';

const FooterCallToAction = () => (
  <div className="w-full bg-primary-blue px-2">
    <div className="container inner-width m-auto py-20 text-center">
      <h2 className="font-bold pb-4 text-white">
        Take the first steps to reducing your debt!
      </h2>
      <FooterCallToActionButton
        buttonLabel="Get Started"
      />
    </div>
  </div>
);

export default FooterCallToAction;
