import React, { useRef } from 'react';
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import emailButton from '../../../assets/icons/email-share_button.svg';
import fbButton from '../../../assets/icons/fb-share_button.svg';
import linkedinButton from '../../../assets/icons/linked-in-share_button.svg';
import twitterButton from '../../../assets/icons/twitter_button.svg';
import { useSelector } from 'react-redux';

const ShareLinks = () => {
  const lead = useSelector((state) => state.lead.data);
  const referralRef = useRef();

  const copyTextToClipboard = (selectedRef) => {
    selectedRef.select();
    document.execCommand('copy');
  };

  const environment = process.env.REACT_APP_ENVIRONMENT;
  const isProd = environment === 'prod';

  const tryUrl = isProd
    ? 'https://try.creditgenie.com'
    : `https://${environment}.try.creditgenie.com`;

  return (
    <div className="share-links mx-auto sm:mt-10 mt-4">
      <div className="text-center pb-8">
        <p className="font-bold pb-2 text-2xl">
          Do you have any friends or family that could use help becoming debt free? Refer them today.
        </p>
      </div>
      <EmailShareButton
        className="inline-block"
        data-cy="email-share-button"
        subject="Take a look at this company Credit Genie that I recently came across."
        body="It's the FIRST completely FREE service that helps folks get out of debt! Right now you can join their waitlist to get first access to this service when it launches. Just head over to the following link to get on the waitlist!"
        url={tryUrl}
      >
        <img
          alt="Share with email"
          className="share-button"
          id="email-button"
          src={emailButton}
        />
      </EmailShareButton>
      <FacebookShareButton
        className="inline-block ml-2"
        data-cy="facebook-share-button"
        quote="Have you heard of Credit Genie? It's the FIRST completely FREE service that helps folks get out of debt! and you can join their waitlist to get first access to this service."
        url={tryUrl}
      >
        <img
          alt="Share on Facebook"
          className="share-button"
          id="facebook-button"
          src={fbButton}
        />
      </FacebookShareButton>
      <LinkedinShareButton
        className="inline-block ml-2"
        data-cy="linkedin-share-button"
        url={tryUrl}
      >
        <img
          alt="Share on LinkedIn"
          className="share-button"
          id="linkedin-button"
          src={linkedinButton}
        />
      </LinkedinShareButton>
      <TwitterShareButton
        className="inline-block ml-2"
        data-cy="twitter-share-button"
        title="Have you heard of Credit Genie? It's the FIRST completely FREE service that helps folks get out of debt! and you can join their waitlist to get first access to this service."
        url={tryUrl}
      >
        <img
          alt="Share on Twitter"
          className="share-button"
          id="twitter-button"
          src={twitterButton}
        />
      </TwitterShareButton>
      <div className="m-auto pt-4">
        <div className="copy-link py-4 mt-4 inline-block">
          <input className="truncate px-4" ref={referralRef} readOnly value={`${tryUrl}?ref=${lead.id}`} />
        </div>
        <button
          id="copy-url-button"
          data-cy="copy-url-button"
          className="call-to-action-button inline-block sm:ml-4 ml-0 share-button"
          onClick={() => copyTextToClipboard(referralRef.current)}
          type="button"
        >
          Copy
        </button>
      </div>
    </div>
  );
};

export default ShareLinks;
