import React, { useRef } from 'react';
import { EmailCaptureForm } from '../../components/common';

const VideoSalesLetterLandingPage = () => {
  const vidRef = useRef();

  return (
    <div className="video-sales-letter-landing-page bg-white">
      <div className="container inner-width m-auto sm:pt-10 text-center sm:px-0 px-2">
        <h1 className="font-bold pb-4 pt-10">
          Need debt relief fast?
        </h1>
        <h2 className="sm:w-3/4 w-full m-auto">
          Our <span className="font-bold">FREE</span> service will help you lower your monthly debt payments.
          <br/>
          It&apos;s <span className="font-bold">fast</span> and <span className="font-bold">simple</span>.
          {' '}
        </h2>
        <div className="video mt-6 m-auto sm:pb-4 pb-8">
          <video controls ref={vidRef}>
            <source src="https://assets.creditgenie.com/leadgenie/credit-genie-promo.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="pt-8 pb-20">
        <h2 className="text-center">
          <span className="font-bold">
            Enter your email address to get started.
          </span>
        </h2>
        <p className="text-center pb-2">We will send you an invite to our free debt analysis tool.</p>
        <EmailCaptureForm />
      </div>
    </div>
  );
};

export default VideoSalesLetterLandingPage;
