import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import Close from '../../../assets/icons/x_icon.svg';
import { setShowVideo } from '../../../redux/actions/videoActions';

const VideoModal = () => {
  const vidRef = useRef();
  const dispatch = useDispatch();
  const onCloseClick = () => {
    dispatch(setShowVideo({ value: false }));
  };

  return (
    <div className="video-modal block">
      <div className="video-bg">
        <div className="video-content">
          <img onClick={onCloseClick} onKeyDown={onCloseClick} tabIndex="0" role="button" className="block float-right pb-4" src={Close} alt="Close" />
          <div className="clearfix" />
          <video controls autoPlay={true} ref={vidRef}>
            <source src="https://assets.creditgenie.com/leadgenie/credit-genie-promo.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;

