/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPoliicy = () => (
  <section>
    <div className="policy-tables leading-normal tracking-normal pb-20">
      <div className="container mx-auto max-w-5xl pl-2 pr-2 pt-8 pb-8">
        <div className="pb-5">
          <h2 className="text-xl sm:text-2xl w-full m-auto text-center">
            Privacy Policy
          </h2>
          <p className="text-center p-5">
            Effective Date:  May 1&#44; 2020
          </p>
          <p>
            This Privacy Policy is meant to help you understand how Creditly Corporation
            (&ldquo;Creditly&#44;&ldquo; &ldquo;we&#44;&ldquo; &ldquo;us&#44;&ldquo; or &ldquo;our&ldquo;) collects&#44;
            secures&#44; uses&#44; and discloses your personal information in connection with our services. This Privacy Policy applies to
            personal information we collect from you and from third parties&#44; both online and offline.
          </p>
          <br />
          <p>
            This Privacy Policy applies to use of <Link to="/" className="color-blue">creditgenie.com</Link>&#44; a Creditly-owned and controlled web site
            (&ldquo;Site&ldquo;). By using <Link to="/" className="color-blue">creditgenie.com</Link>&#44; or otherwise providing us with personal
            information&#44; you agree to the terms and conditions of this Privacy Policy and any updates we make. Our Terms and Conditions of Use for
            the Site (&ldquo;Terms of Use&ldquo;) take precedent over any provision conflicting with this Privacy Policy.
          </p>
        </div>
        <div className="pb-5">
          <h2 className="text-xl">
            Changes to Our Privacy Policy
          </h2>
          <p className="pb-5">
            We update our Privacy Policy regularly &ndash; this version is effective as of the date indicated above.  We reserve the right to
            change this Privacy Policy at any time without notice to you.  If there are any material changes in the way we treat your
            personal information&#44; Creditly will send a notice to the primary email address on your account or place a prominent notice
            on our platform before the change goes into effect. Creditly will also promptly make an updated Privacy Policy publicly
            available on the Site. Changes will be effective as soon as the revised Privacy Policy is posted unless otherwise stated.
          </p>
          <p className="font-bold pb-2">1. HOW AND WHY WE COLLECT INFORMATION ABOUT YOU</p>
          <p className="font-bold pb-2">11.1. Information You Provide Us</p>
          <p className="font-bold pb-2">11.1.1. Personal Information</p>
        </div>
        <div className="pb-5">
          <p>
            We collect personal information from you to help you use our services. When we say&#44; &ldquo;personal information&#44;&ldquo; we mean
            information that specifically identifies you.
          </p>
          <br />
          <p>
            We collect this information in a number of ways. You are asked to provide personal information to us when you use our Site&#44;
            authorize us to obtain information about you from a third party&#44; communicate with us about our services&#44; and provide feedback
            to us. Examples of personal information include:
          </p>
          <ul className="list-disc pl-5 pt-2">
            <li>Name</li>
            <li>Address</li>
            <li>Telephone Number</li>
            <li>Email Address</li>
            <li>Household Composition</li>
            <li>Income Information</li>
            <li>Expense Information</li>
            <li>Credit information</li>
          </ul>
          <br />
          <p>
            Please note that any information&#44; photos&#44; contact information&#44; email addresses&#44; videos&#44; and other content you post to your profile
            on Creditly&apos;s Community site is available to everyone using that service. The information you share is available to everyone
            accessing that service&#44; and may be read&#44; collected or used by others for any purpose&#44; including sending you unsolicited messages.
          </p>
          <br />
          <p>
            We do not knowingly collect personal information from individuals under 18. If you are under 18&#44; do not use or provide any information
            on the Site or on or through any of its features. If we learn we have collected or received personal information from a person under 18
            without verification of parental consent&#44; we will delete that information. If you believe we might have any information from or about
            a person under 18&#44; please contact us at <a href="email:help@creditgenie.com" className="color-blue">help@creditgenie.com</a>.
          </p>
          <br />
          <div className="pb-5">
            <p className="font-bold pb-2">1.2. Information We Collect About You</p>
            <p className="font-bold pb-5">1.2.1. Information Collected</p>
            <p>
              Creditly collects information about your computer or mobile device to improve and evaluate your use of our Site and our services.
              We may gather data such as your browser type&#44; operating system&#44; loading or exiting of web pages&#44; and the Internet Protocol (IP)
              address of your computer or device.
            </p>
          </div>
          <div className="pb-5">
            <p className="font-bold pb-5">1.2.2. Cookie Information</p>
            <p className="pb-5">
              Creditly uses &ldquo;cookies&ldquo; and similar tools to track your use of the Site when you use our services. We collect information
              such as the types of service used&#44; and number of users we receive daily. Our web servers automatically log information about your
              computer or mobile device&#44; but we do not use this information to identify you personally.
            </p>
            <p className="pb-5">
              We and our vendors use cookies and web beacons to improve our service&#44; our Site and to provide more convenient and relevant experiences
              to you. Cookies are designed to transfer a small amount of data to your browser by servers within a domain. That data is
              only read by designated servers within that domain. It functions as your computer&apos;s identification card and enables Creditly
              to improve your experience by securely maintaining your authenticated session and preferences.
            </p>
            <p className="pb-5">
              A web beacon embeds a small transparent gif image in a web page or email used to track when the page or email has been viewed.
              A similar device may be used where a product&#44; service or functionality sends data to a server when a set of user-initiated
              events occur such as clicking a button on the Site or in an email. This is similar to a cookie – it tracks your visit and
              the data is only read by the server that receives the data. However&#44; it differs because it is not browser-based&#44; may not
              function as an ID card and does not store any data on your computer.
            </p>
            <p className="pb-5">
              Most Internet browsers are set up to accept cookies automatically. You can set your browser to notify you when you receive
              a cookie&#44; allowing you to decide whether or not to accept it. For some web pages requiring an authorization&#44; cookies are
              not optional. Users choosing not to accept cookies or similar devices will probably not be able to access those pages.
            </p>
          </div>
          <div className="pb-5">
            <p className="font-bold pb-5">1.2.3 Contacting Us By Telephone</p>
            <p className="pb-5">
              If you communicate with us by telephone&#44; we may monitor or record the call. This is done for reasons such as maintaining
              the integrity of your account&#44; providing effective and timely service&#44; and the improvement of Creditly&apos;s services.
            </p>
          </div>
          <div className="pb-5">
            <p className="font-bold pb-5">1.3. Information Third Parties Provide About You</p>
            <p className="pb-5">
              As a company engaged in helping consumers manage their unsecured personal debt&#44; Creditly receives data from consumer
              reporting agencies. We partner with these agencies to assess your current debt load and to prevent fraud and identity theft.
            </p>
            <p className="pb-2">
              Information we collect in these reports includes&#44; but is not limited to:
            </p>
            <ul className="list-disc pl-5 pb-5">
              <li>
                Information about open and closed credit accounts&#44; including the date opened&#44; the date closed (if applicable)&#44; the latest
                reported monthly balance and monthly payment
              </li>
              <li>
                Information about credit inquiries
              </li>
              <li>
                Information about late payments and collection actions occurring on open and closed credit accounts
              </li>
              <li>
                Information regarding public records of bankruptcy&#44; judgements&#44; tax liens&#44; and other payment statuses
              </li>
              <li>
                The credit score produced by the credit bureau providing the report
              </li>
              <li>
                Information about your bank account transaction history&#44; assets&#44; and account balances
              </li>
            </ul>
            <p className="pb-5">
              Additionally&#44; we may supplement the personal information you submit to us with information from third-party sources. For
              example&#44; we ask you to connect your bank account with Creditly using a third-party service to verify your income.
            </p>
            <p className="pb-5">
              Finally&#44; we contract with third parties to place advertisements for our services on web sites that are not our own. We place
              these advertisements where we think they are likely to be most relevant. These advertisements on third-party web sites allow
              us to track responses to our advertisements. We use this information to track the performance of our advertising and marketing
              campaigns by using tools like cookies&#44; web beacons and similar technologies. We use these tools to collect and store information
              about the user&apos;s visit&#44; page visits and duration&#44; and the specific advertisement or link that the user clicked on to visit
              the Site.
            </p>
          </div>
          <div className="pb-5">
            <p className="font-bold pb-5">
              2. USE OF YOUR PERSONAL INFORMATION
            </p>
            <p className="font-bold pb-5">
              2.1 How We Use Your Personal Information
            </p>
            <p className="pb-5">
              We use your personal information to provide you with information or services you request&#44; to inform you about other information
              and services we think will be of interest to you&#44; to facilitate your use and our operation of our Site&#44; and to improve our services.
              We do not forget about you once you have received our services—Creditly provides services to propel your financial future.
            </p>
            <p className="pb-2">
              Here are some examples of ways in which we use your personal information:
            </p>
            <ul>
              <li>To create and maintain your login information on the Site</li>
              <li>To contact you regarding issues with your online profile</li>
              <li>To send you information about new services</li>
              <li>To generate a personal debt score</li>
            </ul>
          </div>
          <div className="pb-5">
            <p className="font-bold pb-5">
              2.2. Aggregate Data
            </p>
            <p className="pb-5">
              Aggregate data means records which have been stripped of information potentially identifying customers&#44; landing pages or end-users&#44;
              and which have been manipulated or combined to provide generalized&#44; anonymous information. Your identity and personal information
              will be kept anonymous in aggregated data. Aggregate data is used to help us understand consumer trends&#44; needs&#44; interests&#44; and
              preferences so we can improve our products and services.
            </p>
            <p className="pb-2">
              Creditly also collects demographic information&#44; and may include:
            </p>
            <ul className="list-disc pl-5">
              <li>Age</li>
              <li>Date of Birth</li>
              <li>Income</li>
              <li>Geographic Area</li>
            </ul>
          </div>
          <div className="pb-5">
            <p className="font-bold pb-5">
              3. HOW WE SHARE YOUR PERSONAL INFORMATION
            </p>
            <p className="pb-2">
              We may share information&#44; including personal information&#44; with third parties in a variety of ways&#44; such as:
            </p>
            <ul className="list-disc pl-5">
              <li>
                <span className="font-bold">Third parties acting on our behalf:</span> We may share information about you with service providers&#44;
                partners or agents in order to have them perform on our behalf any of the activities we are permitted to conduct under this Privacy
                Policy and our Terms of Use. This includes things like designing or operating features&#44; helping us research and test feature
                improvements&#44; identifying offers or personalized recommendations&#44; performing analytics and marketing our services. These third
                parties are acting on our behalf so while they may have access to your information&#44; including things like your credit reports
                and scores&#44; they are required to follow our instructions and meet the standards explained in our Privacy Policy when handling
                information about you that we provide them.
              </li>
              <li>
                <span className="font-bold">Non-affiliated third-party creditors:</span> We may share information with creditors with whom you
                already have a relationship in connection with providing our services.
              </li>
              <li>
                <span className="font-bold">Affiliated third party partners:</span> We may share information about you with affiliated third
                party partners&#44; such as non-profit credit counseling organizations.  Non-profit credit counselors do not work for Creditly&#44;
                but instead work directly with you the consumer to provide financial education and impartial advice on debt management.
                In order to facilitate your interaction with a credit counselor&#44; Creditly may share information about you with the counselor.
                Creditly works exclusively with high-quality&#44; trusted credit counseling agencies that are members of the National Foundation
                for Credit Counseling.  Creditly receives no incentive or bonus payment from a credit counselor should you choose to enroll
                in a debt management plan.
              </li>
              <li>
                <span className="font-bold">Legal reasons:</span> We reserve the right to transfer or disclose information about you to third
                parties for certain legal purposes. With your consent: There may also be other limited contexts in which we share specific
                types of personal information with your express consent.
              </li>
            </ul>
          </div>
          <div className="pb-5">
            <p className="font-bold pb-5">
              4. PROTECTING YOUR PERSONAL INFORMATION
            </p>
            <p className="font-bold pb-5">
              4.1. What We Do to Protect Your Personal Information
            </p>
            <p className="pb-5">
              Creditly takes seriously the privacy and security of consumers&apos; personal information. We maintain administrative&#44; technical
              and physical safeguards designed to protect your information&apos;s security&#44; confidentiality and integrity.
            </p>
            <p className="pb-5">
              We protect personal information you provide online in connection with registering yourself as a user of our Site. Access to
              your own personal information is available through a unique user ID and password selected by you. This password is encrypted
              while transmitted from your browser to our servers and while stored on our systems. To protect the security of your personal
              information&#44; never share your password to anyone. Please notify us immediately if you believe your password has been compromised.
            </p>
            <p className="pb-5">
              Whenever we save your personal information&#44; it&apos;s stored on servers and in facilities that only selected Creditly personnel and
              our contractors have access to. We encrypt all data that you submit through the Site during transmission using Transport Layer
              Security (TLS) in order to prevent unauthorized parties from viewing such information. Remember – all information you submit
              to us by email is not secure&#44; so please do not send sensitive information in any email to Creditly.
            </p>
          </div>
          <div className="pb-5">
            <p className="font-bold pb-5">
              4.2. Steps You Can Take to Help Us Keep Your Information Safe
            </p>
            <p className="pb-5">
              There are many steps you can take to help us keep your information safe. First and foremost&#44; choose complex&#44; independent
              passwords for each website and service with which you maintain an account or profile. This helps keep any breach of any
              of your accounts or profiles isolated to one service.
            </p>
            <p className="pb-5">
              Creditly urges your caution when using public computers or networks&#44; like at a coffee shop or library. To best protect your
              personal information and login information&#44; do not use such computers to access your sensitive accounts&#44; and if you must&#44;
              ensure that you logout of your account entirely.
            </p>
            <p className="pb-5">
              When either you or we update information in your account&#44; such as the status of a loan application&#44; we typically send you
              notice of these changes via email or text message. In recent years&#44; individuals&#44; businesses and even governments have seen
              a rise in “phishing” attacks. Phishing occurs when someone attempts to obtain your password or other sensitive information.
              Scammers often do this by impersonating a trusted user or offering a compelling reason to open a malicious email attachment&#44;
              click on a link or give over information. We never ask for your sensitive personal information&#44; such as password&#44; over email
              or other unsecure methods or through any site not under the Creditly.com domain. Please notify us at
              {' '}
              <a href="mailto:help@creditgenie.com" className="color-blue">help@creditgenie.com</a> if you ever receive suspicious
              correspondence from us.
            </p>
          </div>
          <div className="pb-5">
            <p className="font-bold pb-b">
              5. YOUR CHOICES REGARDING YOUR PERSONAL INFORMATION
            </p>
            <p className="pb-5">
              We want you to be aware of the choices you have in limiting the sharing of your personal information. Please refer to the
              below table to review your options in sharing with us:
            </p>
            <table>
              <thead>
                <tr>
                  <th>Reason we share this information</th>
                  <th>Can this sharing be limited?</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>For our everyday business purposes—such as to process your application or maintain your online profile</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>Improve our products and services</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>For joint marketing with other financial companies</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>For our marketing purposes—to offer our products and services to you</td>
                  <td>No</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="pb-5">
            <p className="font-bold pb-5">
              5.1. Your Rights as a California Resident
            </p>
            <p className="pb-5">
              This section contains disclosures required by the California Consumer Privacy Act (&ldquo;CCPA&ldquo;) and
              applies only to &ldquo;personal information&ldquo; that is subject to that law.  Effective January 1&#44; 2020&#44;
              the CCPA allows California residents&#44; upon a verifiable consumer request&#44; to request the following:
            </p>
            <ul className="list-disc pl-5">
              <li>
                For certain categories of personal information&#44; <span className="font-bold">the right to request</span> a list of
                what personal information (if any) we disclosed to third parties for their own direct marketing purposes in the preceding
                calendar year and the names and addresses of those third parties.  You may view a comprehensive list of the categories
                of information we have shared with corresponding categories of third parties within the last 12 months at
                {' '}
                <a
                  href="/vendor-sharing"
                  className="color-blue"
                >
                  https://www.creditgenie.com/vendor-sharing/
                </a>
              </li>
              <li>
                The <span className="font-bold">right to know</span> the categories and specific pieces of personal information we collect&#44;
                use and disclose about you&#44; the categories of sources from which we collected your personal information&#44; our purposes for
                collecting your personal information&#44; the categories of your personal information that we have disclosed for a business
                purpose&#44; and the categories of third parties with which we have shared personal information. The business purpose for which
                we have collected your information.
              </li>
              <li>
                The <span className="font-bold">right to request that we delete</span> the personal information we have collected from you or
                maintain about you&#44; subject to certain legal exceptions&#44; for example&#44; when the personal information is necessary to complete a
                transaction for which we collected it or to comply with a legal obligation.
              </li>
              <li>
                The <span className="font-bold">right to opt out</span> of our sale(s) of your personal information. Please be advised that we do
                not currently sell personal information. This opt-out&#44; however&#44; would apply to the extent we began to engage in sales of personal
                information governed by the CCPA.
              </li>
              <li>
                The <span className="font-bold">right not to receive discriminatory treatment</span> for the exercise of the privacy rights
                conferred by the CCPA.
              </li>
            </ul>
          </div>
          <div className="pb-5">
            <p className="pb-5">
              To limit our sharing once your creditgenie.com account has been created&#44; email us at
              {' '}
              <a href="mailto:help@creditgenie.com">help@creditgenie.com.</a>
            </p>
            <p className="pb-5">
              Our Site contains links to other sites that may collect your personal information. For example&#44; Creditly has relationships with
              various service providers and partners whose services are available to you from our Site&#44; from other websites that are linked to
              our Site&#44; or in connection with our services. These partners offer services we think our customers may find useful or will improve
              our services. Those providers may require you to provide personal information to use their services. Personal information collected
              on other websites is not covered by this Privacy Policy. Unless stated otherwise&#44; the policies of those partners will govern the
              collection&#44; use&#44; disclosure and security of your personal information.
            </p>
            <p className="pb-5">
              At this time&#44; we do not respond to &ldquo;do not track&ldquo; signals or similar mechanisms – where a visitor to our Site requests
              that we disable the collection of information about the visitor&apos;s online activities&#44; including navigation around our Site and the
              service. We may also permit third parties to collect aggregate usage information on the Site&#44; and they may also not respond to
              &ldquo;do not track&ldquo; signals.
            </p>
          </div>
          <div className="pb-5">
            <p className="font-bold pb-5">
              5.2. Changing and Updating Your Personal Information
            </p>
            <p className="pb-5">
              We want to provide you with the resources you need to keep your information with us correct and up-to-date. If you need to change
              any other information in your profile&#44; please contact our customer service at
              {' '}
              <a className="color-blue" href="mailto:help@creditgenie.com">help@creditgenie.com.</a>
            </p>
          </div>
          <div className="pb-5">
            <p className="font-bold pb-5">
              6. COMPLAINTS &amp; CONCERNS
            </p>
            <p className="pb-5">
              Creditly takes your privacy seriously and maintains a process to respond to your concerns regarding violations of this policy.
              If you believe that we have not complied with this policy in regards to your personal information or have questions regarding
              your privacy with us&#44; please contact us at <a className="color-blue" href="mailto:help@creditgenie.com">help@creditgenie.com.</a>.
              If you are reporting non-compliance with this Privacy Policy&#44; please describe the issue in as much detail as possible without disclosing
              any sensitive information about you or third parties. We will respond to your request by email within 7-10 business days.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default PrivacyPoliicy;
