import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveLead,
  setSaveLeadSucceeded,
  updateLead,
} from '../../../redux/actions/actions';

const useForm = ({ callback= () => {}, validate }) => {
  const dispatch = useDispatch();
  const lead = useSelector((state) => state.lead.data);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const saveLeadFailed = useSelector((state) => state.lead.saveFailed);
  const [shouldSaveLead, setShouldSaveLead] = useState(false);

  const giveFocusToFirstErrorElement = (validationErrors) => {
    let firstErrorElement;

    const firstErrorElementName = Object.keys(validationErrors)[0];

    const matchingNodesByElementName = document.getElementsByName(firstErrorElementName);

    firstErrorElement = matchingNodesByElementName.length ? matchingNodesByElementName[0] : undefined;

    if (firstErrorElement) {
      firstErrorElement.focus();

      return;
    }

    const matchingNodesByValidationName = document.querySelectorAll(`[validationname="${firstErrorElementName}"]`);

    firstErrorElement = matchingNodesByValidationName.length ? matchingNodesByValidationName[0] : undefined;

    if (firstErrorElement) {
      firstErrorElement.focus();
    }
  };

  const onLeadChange = (event) => {
    const { name, value } = event.target;

    dispatch(updateLead({ propertyName: name, value }));

    setShouldSaveLead(true);

    if (isSubmitting) {
      validateComponent({ event });
    };
  };

  const onTextInputBlur = (event, valueOverride, updateFunction) => {
    const { target: element } = event;
    const { name, type, value } = element;

    const trimmedValue = valueOverride !== undefined ? valueOverride : value.trim();

    if (type === 'email') {
      // inputs of type email automatically ignore leading and trailing spaces regarding their internal values, but preserve them in the actual inputs
      // As a result, updating the values to the trimmed values doesn't actually change anything, and the spaces remain in the inputs
      // We can get around this by removing the values entirely and then updating them to the trimmed values
      element.value = undefined;
    }

    dispatch(updateFunction({ propertyName: name, value: trimmedValue === '' ? undefined : trimmedValue }));

    if (isSubmitting) {
      validateComponent({ event });
    }
  };

  const validateElements = ({ elements = {} }) => {
    if (!elements || !Array.isArray(elements) || !elements.length > 0) {
      return {};
    }

    const elementValues = {};
    const emptyValues = {};
    const regularExpressions = {};
    const requiredValues = {};

    elements.forEach((element) => {
      const elementName = element.attributes.validationName ? element.attributes.validationName.value : element.name;

      elementValues[elementName] = element.value;

      emptyValues[elementName] = undefined;
      requiredValues[elementName] = element.required;

      if (element.attributes.regex) {
        regularExpressions[elementName] = element.attributes.regex.value;
      }
    });

    const validationErrors = validate({
      regularExpressions,
      requiredValues,
      values: {
        ...emptyValues,
        ...elementValues,
      },
    });

    return validationErrors;
  };

  const validateForm = ({ event }) => {
    const validationErrors = validateElements({ elements: [...event.target] });

    setErrors(validationErrors);

    return validationErrors;
  };

  const validateComponent = ({ event }) => {
    const element = event.target;
    const elementName = element.attributes.validationName ? element.attributes.validationName.value : element.name;

    const validationErrors = validateElements({ elements: [element] });

    setErrors({ ...errors, [elementName]: validationErrors[elementName] });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    const validationErrors = validateForm({ event });

    if (Object.keys(validationErrors).length > 0) {
      giveFocusToFirstErrorElement(validationErrors);

      return;
    }

    if (shouldSaveLead || saveLeadFailed) {
      dispatch(saveLead());
    } else {
      dispatch(setSaveLeadSucceeded());
    }
  };

  useEffect(() => {
    if (isSubmitting && Object.keys(errors).length === 0) {
      callback();

      setIsSubmitting(false);

      return;
    }
  }, [
    callback,
    errors,
    isSubmitting,
  ]);

  return {
    onLeadChange,
    onLeadTextInputBlur: (event, valueOverride) => onTextInputBlur(event, valueOverride, updateLead),
    onSubmit,
    errors,
    lead,
  }
};

export default useForm;
