import React from 'react';
import videoThumbnail from '../../../assets/images/video_thumbnail.svg';
import { useDispatch } from 'react-redux';
import PlayButton from '../../../assets/icons/play_icon_orange.svg';
import { setShowVideo } from '../../../redux/actions/videoActions';

const HowItWorksVideo = () => {
  const dispatch = useDispatch();

  const onWatchTheVideoClick = () => {
    dispatch(setShowVideo({ value: true }));
  };

  return (
    <div className="how-it-works-video sm:py-20 py-10">
      <div className="container inner-width m-auto sm:flex flex-none">
        <div>
          <img src={videoThumbnail} alt="Video thumbnail" />
        </div>
        <div className="video text-white pt-8 pl-8">
          <h2 className="sm:w-3/4 w-full">
            Meet the world&apos;s first FREE debt relief service.
          </h2>
          <div className="pt-4 outline-none" onClick={onWatchTheVideoClick} onKeyDown={onWatchTheVideoClick} role="button" tabIndex="0">
            <img className="inline-block" src={PlayButton} alt="Play button" />
            <div className="inline-block ml-2">
              Watch the video
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksVideo;
