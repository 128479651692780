/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link } from 'react-router-dom';

const CreditReportPolicy = () => (
  <section>
    <div className="leading-normal tracking-normal grey-bg pb-20">
      <div className="container mx-auto max-w-5xl pl-2 pr-2 pt-8 pb-8">
        <div className="pb-5">
          <h2 className="text-xl sm:text-2xl w-full m-auto text-center">
            Terms and Conditions of Use
          </h2>
          <p className="text-center p-5">
            Effective Date:  May 1, 2020
          </p>
        </div>
        <div className="pb-5">
          <p className="pb-5">
            Please review these Terms and Conditions of Use (&ldquo;Terms of Use&ldquo;) before using this site or accessing
            any data on the Credit Genie platform. If you do not agree to these terms without limitation or exclusion, you
            should exit this site. These Terms of Use are effective immediately for new users, and from May 1, 2020 for existing users.
          </p>
          <p className="pb-5">
            These Terms of Use apply to the World Wide Web site located at<Link to="/">www.creditgenie.com</Link>, including any subdomain
            thereof (&ldquo;Site&ldquo;), which is owned, operated, licensed, and controlled by Creditly Corporation
            (&ldquo;Creditly,&ldquo; &ldquo;we,&ldquo; or &ldquo;us&ldquo;) a Delaware corporation. Creditly reserves the right at its sole
            discretion, to change, modify, add or remove portions of these Terms of Use, at any time. Creditly also reserves the right to
            modify or discontinue, temporarily or permanently, the Site for any reason, at its sole discretion, with or without notice to you.
            You agree to check these Terms of Use, our Privacy Policy and our Credit Reporting Policy periodically for changes. Your continued
            use of the Site following the posting of changes will mean that you accept and agree to the changes.
          </p>
          <p className="pb-5">
            If applicable, your spouse or domestic partner may be asked to provide personally identifiable information in order to identify the
            offers that may be available to you based on your household income and liabilities.  Where such information is required, your spouse
            or domestic partner must also review these Terms of Use, the Privacy Policy and the Credit Reporting Policy before using this Site or
            accessing any data on the Credit Genie platform. If your spouse or domestic partner does not agree to these terms without limitation or
            exclusion, you and your spouse or domestic partner should exit this Site.
          </p>
        </div>
        <div className="pb-5">
          <h2 className="pb-5">PRIVACY</h2>
          <p className="pb-5">
            Please review the <Link to="/privacy-policy">Privacy Policy</Link> to understand our how we use, share and safeguard your personal
            information. By using the Site, you acknowledge and agree that Internet transmissions are never completely private or secure. You
            understand that any message or information you send to the Site may be read or intercepted by others, notwithstanding Creditly&apos;s
            efforts to protect such transmissions.
          </p>
        </div>
        <div className="pb-5">
          <h2 className="pb-5">YOUR USE OF SITE</h2>
          <p className="pb-5">
            By accessing or using the Site, you certify that you have read, understand and agree to be bound by these Terms of Use, regardless
            of whether you use the information intake and compilation services provided through the Site (&ldquo;Services&ldquo;). You agree
            to abide by all applicable local, state, national, and international laws and regulations in regard to your use of the Site. Our
            Site is available only to individuals who are permitted to use it under applicable law. If you do not qualify, please do not use
            our Site. You agree that all access and use of the Site and its contents is at your own risk. You agree to be solely responsible
            for your actions and the contents of your transmissions through the Site. You agree not to impersonate any person or entity or
            falsely state or otherwise misrepresent your identity or affiliation with a person or entity. You agree that we may terminate
            your password, account, or use of the Site in Creditly&apos;s sole discretion for any reason as permitted by law. If you agree
            to and abide by the Terms of Use, Creditly grants you a personal, non-exclusive, non-transferable, limited privilege to enter
            and use the Site.
          </p>
          <p className="pb-5">
            All notices you give to us shall be in writing and shall be made either via email or conventional mail. In addition, Creditly may
            post notices or links to notices through the Site to inform you of changes to the Terms of Use, the Site, or other matters of importance.
          </p>
          <p className="pb-5">
            You agree that you will not use any robot, spider, other automatic device, or manual process to monitor or copy our Web pages or
            the content contained herein without our prior expressed written permission. You agree that you will not use any device, software
            or routine to interfere or attempt to interfere with the proper working of the Site or any information or technology obtained from
            the Site.
          </p>
          <p className="pb-5">
            You agree that all access and use of the Site and its contents is at your own risk. By using the Site, you acknowledge that we
            specifically disclaim any liability (whether based in contract, tort, negligence, strict liability or otherwise) for any direct,
            indirect, incidental, common law, statutory, regulatory, consequential, compensatory, punitive, or special damages arising out of
            or in any way connected with your access to or use of the Site (even if we have been advised of the possibility of such damages)
            including, but not limited to, any liability associated with any viruses which may infect your computer equipment.
          </p>
          <p className="pb-5">
            Calculators and tools on the Site provide you with ESTIMATES that may be different than actual amounts.
          </p>
        </div>
        <div className="pb-5">
          <h2 className="pb-5">
            ACCOUNT INFORMATION FROM ONLINE FINANCIAL SERVICE PROVIDER SITES
          </h2>
          <p className="pb-5">
            Creditly offers a &ldquo;Connect Accounts&ldquo; feature through which you may direct Creditly to retrieve your information maintained
            online by third-party financial institutions with which you have customer relationships, maintain accounts, or engage in financial
            transactions (&ldquo;Account Information&ldquo;). Creditly works with one or more online financial service providers under contract
            to access this Account Information (&ldquo;Online Financial Service Provider&ldquo;). Creditly does not store any user account
            credentials for your information. The Online Financial Service Provider store your login credentials securely in a separate database
            using multi-layered hardware and software encryption.
          </p>
          <p className="pb-5">
            Creditly uses the information to which you provide us access from Online Financial Service Provider sites – including account balances,
            transaction history, and age of account – in order to assess your eligibility for a debt management plan. Creditly is not responsible
            for the accuracy of the information maintained by or on Online Financial Service Provider sites, nor for products or services offered
            by or on such sites. If you access and use services from such Online Financial Service Provider sites, you are responsible for
            reviewing and understanding the terms and conditions governing such services and sites. Creditly has no control over Online Financial
            Service Provider services and sites.  Your ability to access and use such services and sites may be subject to suspension or
            termination at any time, for any reason, at the  Online Financial Service Provider&apos;s discretion.
          </p>
        </div>
        <div className="pb-5">
          <h2 className="pb-5">
            SHARING PERSONALLY IDENTIFIABLE INFORMATION WITH THIRD PARTIES
          </h2>
          <p className="pb-5">
            Except as provided below, your personal information, including your name, address, contact information, intake information, financial
            information, and aggregated credit bureau data collected by Creditly on a consumer (collectively, &ldquo;Consumer Information&ldquo;)
            and any derivative work prepared from your Consumer Information will not be shared with or sold to third parties.  Creditly reserves
            the right to share your Customer Information and any derivative work from your Customer Information with (i) a third party as permitted
            by law and disclosed in our Privacy Policy; (ii) the National Foundation for Credit Counseling (&ldquo;NFCC&ldquo;) and any of its
            member organizations for the sole purpose of allowing its certified credit counselors and other financial advocates to provide credit
            counseling, credit evaluations, and debt management services to you in connection with the Services offered by Creditly.
          </p>
        </div>
        <div className="pb-5">
          <h2 className="pb-5">
            RIGHTS YOU GRANT TO US
          </h2>
          <p className="pb-5">
            By submitting information, data, passwords, usernames, PINs, other sign-in information, materials and other content to Creditly
            through the Site, you are licensing that content to Creditly for the purpose of evaluating your financial history and potentially
            providing you with debt management related services to you. Creditly will store and use this information from such connected accounts
            for a reasonable period of time in connection with your debt management plan. By submitting this content to Creditly, you represent
            that you are entitled to submit it to Creditly for use for this purpose, without any obligation by Creditly to pay any fees or be
            subject to any other limitations.
          </p>
          <p className="pb-5">
            By using the Connect Accounts feature, you expressly authorize Creditly&apos;s designated third-party service provider to access your
            Account Information maintained by identified third-parties on your behalf. Creditly&apos;s third-party service provider will submit
            information, including usernames and passwords, that you have provided to sign you into the third-party site. You hereby authorize
            Creditly and its designated third-party service provider to store and use information submitted by you to the Site to accomplish
            the foregoing and to configure the Site so that it is compatible with the third-party sites for which you submit your information.
            This authorization will continue for a reasonable period of time in connection with your request for unsecured consumer debt relief.
            You may request to disconnect your connected accounts by notifying Creditly at <a href="mailto:help@creditgenie.com">help@creditgenie.com</a>
          </p>
          <p className="pb-5">
            You understand and agree that the Site is not sponsored by any third-party service provider that is accessible through the Site.
          </p>
        </div>
        <div className="pb-5">
          <h2 className="pb-5">
            PERSONAL INFORMATION RETENTION
          </h2>
          <p className="pb-5">
            Creditly will maintain information necessary to enable Creditly to provide requested Services, and will do so for at least as long
            as it takes Creditly to provide any such service or as otherwise required by law or by legal mandate. You may choose to opt out of
            allowing Creditly to use your personally identifiable information for marketing purposes. If you have chosen to opt out of allowing
            Creditly to use your personal details for marketing purposes, Creditly may still need to keep your personal details to ensure that
            our systems reflect your preferences. Creditly may keep records of any transactions you enter into with us for a minimum of six years
            from the end of our relationship with you. We may keep other information about you to the extent necessary to comply with applicable
            laws and for further legitimate business needs.
          </p>
        </div>
        <div className="pb-5">
          <h2 className="pb-5">
            INTELLECTUAL PROPERTY
          </h2>
          <p className="pb-5">
            The contents of the Site including its &ldquo;look and feel&ldquo; (e.g., text, graphics, images, logos, and button icons), editorial
            content, notices, software (including html-based computer programs), and other material are protected under both United States and
            foreign copyright, patent, trademark, and other laws. The contents belongs to Creditly or to others as indicated. All rights, title
            and interests therein shall remain the property of Creditly and/or such third-parties. Intellectual property includes but is not
            limited to copyrights, patents, database rights, graphics, designs, text, logos, trade dress, trademarks and service marks.
          </p>
          <p className="pb-5">
            You are authorized solely to view and retain a copy of the pages of the Site for your own personal, non-commercial use. You may also
            view and make copies of relevant documents, pages, images or other materials on the Site for the purpose of transacting business with
            Creditly. You agree that you will not duplicate, publish, modify, create derivative works from, participate in the transfer of, post
            on the World Wide Web, or in any way distribute or exploit the Site, or any portion of the Site for any public or commercial use
            without the express written consent of Creditly. Additionally, you agree that you will not: (i) remove or alter any author, trademark
            or other proprietary notice or legend displayed on the Site (or printed pages produced from the Site); and (ii) make any other
            modifications to any documents obtained from the Site, other than in connection with completing information required to transact
            business with Creditly.
          </p>
        </div>
        <div className="pb-5">
          <h2 className="pb-5">
            COMMUNICATION
          </h2>
          <p className="pb-5">
            When you visit the Site, send emails to us or provide us with a phone number, you are communicating with us electronically and you
            consent to receive communications from us electronically and via phone call to the extent permitted by law. We will communicate
            with you primarily by email, text message, phone or by posting notices on the Site. (Communication by text message is not required
            and you may choose to instruct Creditly to stop such communications at any time.) You agree that all agreements, notices, disclosures
            and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing
            to the extent permitted by law. You agree that we may use electronic communications for the purpose of advising you of changes or
            additions to the Site, informing you of loan or loan application status and activities or about any of our products or services,
            or for such other purposes as we deem appropriate and as permitted by law.
          </p>
        </div>
        <div className="pb-5">
          <h2 className="pb-5">
            COOKIES
          </h2>
          <p className="pb-5">
            &ldquo;Cookies&ldquo; are small data files transferred by a website to your computer&apos;s hard drive. Creditly or its service
            providers send cookies when you visit the Site or sites where Creditly ads appear, make purchases, request or personalize information,
            or register yourself for certain services. Accepting the cookies used on the Site, sites that are &ldquo;powered by&ldquo; another
            company on Creditly&apos;s behalf, or sites where our ads appear may give us access to information about your browsing preferences,
            which we may use to personalize and enhance your experience. Cookies are typically classified as either &ldquo;session&ldquo;
            cookies or &ldquo;persistent&ldquo; cookies.
          </p>
          <p className="pb-5">
            Session cookies do not stay on your computer after you close your browser. Persistent cookies remain on your computer until you delete
            them or they expire. Generally speaking, web browsers automatically accept cookies by default. That being said, you can typically
            prevent cookies or selectively accept cookies by adjusting the preferences in your browser. If cookies are not accepted, most features
            of the Site will not be available to you.
          </p>
          <p className="pb-5">
            Creditly does not store passwords or any other information about a visitor without a Creditly account in a cookie that would identify
            them, locate them, or determine their preferences or their financial activity. Aggregated client information may be used to help
            Creditly assess the performance of this Site and develop strategies to maximize utility.
          </p>
        </div>
        <div className="pb-5">
          <h2 className="pb-5">
            DATA USE
          </h2>
          <p className="pb-5">
            We shall have the right, in our sole discretion and with reasonable notice, to establish or change limits concerning use of the Site
            features, temporarily or permanently, including but not limited to the number of times and the maximum duration for which you may
            access the Site in a given period of time. We reserve the right to make any such changes effective immediately to maintain the
            stability or security of the system or Licensee Access Information or to comply with any laws or regulations. You may reject changes
            by discontinuing use of the Site features to which such changes relate. Your continued use of the Site will constitute your
            acceptance of and agreement with such changes. Maintenance upon the Site may be performed from time-to-time resulting in interrupted
            service, delays or errors in the Site features. Attempts to provide prior notice of scheduled maintenance will be made, but Creditly
            cannot guarantee that such notice will be provided.
          </p>
        </div>
        <div className="pb-5">
          <h2 className="pb-5">
            LINKS TO OTHER WEBSITES AND SERVICES
          </h2>
          <p className="pb-5">
            The Site may contain links to outside services and resources, the availability and content of which Creditly does not control. We
            are not responsible for examining or evaluating, and we cannot guarantee and expressly do not warrant the offerings of these
            businesses or individuals or the content of their web sites. Except where an endorsement or relationship is expressly disclosed,
            links to and from such sites do not constitute a Creditly endorsement, and Creditly does not represent or imply that there is any
            business relationship between Creditly and the other entities. Creditly does not assume any responsibility or liability for the
            actions, products, and content of these and any other third-parties. Any concerns regarding any such service or resource, or any
            link thereto, should be directed to the particular outside service or resource.
          </p>
        </div>
        <div className="pb-5">
          <h2 className="pb-5">
            SITE SECURITY
          </h2>
          <p className="pb-5">
            As a condition of your use of the Site, you agree that you will not, and you will not take any action intended to: (i) access data
            that is not intended for you; (ii) invade the privacy of, obtain the identity of, or obtain any personal information about any
            Creditly client or other user of the Site; (iii) probe, scan or test the vulnerability of the Site or the Creditly network or
            breach security or authentication measures without proper authorization by Creditly; (iv) attempt to interfere with service to
            any user, host or network or otherwise attempt to disrupt our business, including, without limitation, via means of submitting
            a virus to the Site, overloading, &ldquo;scraping,&ldquo; &ldquo;flooding,&ldquo; &ldquo;spamming,&ldquo; &ldquo;credential
            stuffing,&ldquo; &ldquo;mail bombing,&ldquo; or &ldquo;crashing;&ldquo; or (v) send unsolicited email or other electronic
            communication, including promotions and/or advertising of products and services. Violations of system or network security may
            result in civil or criminal liability.
          </p>
        </div>
        <div className="pb-5">
          <h2 className="pb-5">
            DEVELOPER APPLICATIONS SECURITY
          </h2>
          <p className="pb-5">
            You also agree that you will not permit any third-party to, directly or indirectly: (i) reverse engineer, decrypt, decompile,
            decode, disassemble, or otherwise attempt to obtain the source code to the applications developed or owned by Creditly (the
            &ldquo;Developer Applications&ldquo;) that are contained in the Site; (ii) rent or time-share the Developer Application or
            host the Developer Application in a multi-tenant environment; (iii) remove or destroy any copyright notices, proprietary
            markings or confidential legends placed upon or contained within the Developer Application or any copies thereof; (iv) engage
            in any activity with the Developer Application that interferes with, disrupts, damages, or accesses in an unauthorized manner
            the servers, networks, or other properties or services of any third-party; and (v) use the Developer Application or data provided
            via the Developer Application in any way in furtherance of criminal, fraudulent, or other unlawful activity. You must comply
            with applicable laws and regulations in using, accessing or distributing the Developer Application, including any data provided
            via the Developer Application.
          </p>
        </div>
        <div className="pb-5">
          <h2 className="pb-5">
            LIMITATION OF LIABILITY
          </h2>
          <p className="pb-5">
            EXCEPT WHERE PROHIBITED BY LAW, NEITHER CREDITLY, NOR ITS PARENT COMPANY, AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS,
            CONTRACTORS, SUCCESSORS, OR ASSIGNS OF EACH, SHALL BE LIABLE TO YOU FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL
            OR PUNITIVE DAMAGES, INCLUDING LOST PROFITS, EVEN IF CREDITLY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. UNLESS
            OTHERWISE EXPRESSLY SET FORTH IN A WRITTEN AGREEMENT WITH YOU, CREDITLY&apos;S MAXIMUM LIABILITY FOR ALL CLAIMS ARISING OUT OF
            OR RELATING TO THE SITE, OR ITS CONTENT, WHETHER IN CONTRACT, TORT OR OTHERWISE SHALL BE LIMITED TO THE AMOUNT YOU PAID TO
            ACCESS THE SITE.
          </p>
          <p className="pb-5">
            NOTWITHSTANDING THE FOREGOING, BECAUSE SOME JURISDICTIONS DO NOT ALLOW FOR THE EXCLUSION OR LIMITATION OF LIABILITY TO THE
            FULL EXTENT DESCRIBED ABOVE, SOME OF THESE EXCLUSIONS MAY NOT APPLY TO YOU. IF THESE LIMITATIONS OR EXCLUSIONS ARE HELD TO
            BE INVALID OR UNENFORCE-ABLE FOR ANY REASON, THE MAXIMUM COLLECTIVE LIABILITY OF CREDITLY, ITS OFFICERS, DIRECTORS, EMPLOYEES,
            AGENTS, PARENT COMPANY AND AFFILIATES, IF ANY, FOR LOSSES OR DAMAGES SHALL NOT EXCEED $500. ALL OTHER DAMAGES, DIRECT OR
            INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE, ARISING OUT OF OR RELATING TO USE OF THE SITE ARE HEREBY EXCLUDED
            EVEN IF CREDITLY, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, PARENT COMPANY OR AFFILIATES HAVE BEEN ADVISED OF THE POSSIBILITY
            OF DAMAGES.
          </p>
        </div>
        <div className="pb-5">
          <h2 className="pb-5">
            MINORS
          </h2>
          <p className="pb-5">
            The Site is not directed at children under the age of eighteen (18) and Creditly does not knowingly collect personal information
            from any child under the age of eighteen (18) at the Site.
          </p>
        </div>
        <div className="pb-5">
          <h2 className="pb-5">
            SEVERABILITY
          </h2>
          <p className="pb-5">
            Any failure by Creditly to exercise any rights or enforce any of these Terms of Use shall not constitute a waiver of such rights
            or terms. If any provision of these Terms of Use or their application in a particular circumstance is held to be invalid or
            unenforceable to any extent, the remainder of these Terms of Use, or the application of such provision in other circumstances,
            shall not be affected thereby, and each provision hereof shall be valid and enforced to the fullest extent permitted by law.
            These Terms of Use, the Privacy Policy and the Credit Reporting Policy collectively constitute the entire agreement between you
            and Creditly with regard to your use of the Site, and any and all other written or oral agreements or understandings previously
            existing between you and Creditly with respect to such use are hereby superseded and cancelled.
          </p>
        </div>
        <div className="pb-5">
          <h2 className="pb-5">
            GOVERNING LAW
          </h2>
          <p className="pb-5">
            These Terms of Use are governed by the laws of the United States of America and the laws of the State of Delaware.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default CreditReportPolicy;
