import {
    GET_LEAD_COUNT,
    GET_LEAD_COUNT_FAIL,
    GET_LEAD_COUNT_SUCCESS,
    SAVE_LEAD_COUNT,
    SAVE_LEAD_COUNT_FAIL,
    SAVE_LEAD_COUNT_SUCCESS,
    UPDATE_LEAD_COUNT,
  } from '../actions/leadCountActions';

  const initialState = {
    data: {
      id: undefined,

      value: 0,
    },
    hasGottenLeadCount: undefined,
    isGettingLeadCount: undefined,
    isSaving: undefined,
    saveFailed: undefined,
    saveSuccess: undefined,
  };

  const leadCountReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_LEAD_COUNT:
        return {
          ...state,
          hasGottenLeadCount: undefined,
          isGettingLeadCount: true,
        };

      case GET_LEAD_COUNT_FAIL:
        return { ...state, hasGottenLeadCount: false, isGettingLeadCount: false };

      case GET_LEAD_COUNT_SUCCESS:
        return {
          ...state,
          data: action.leadCount ? { ...action.leadCount, value: action.leadCount.value } : { value: 0 },
          hasGottenLeadCount: true,
          isGettingLeadCount: false,
        };

      case SAVE_LEAD_COUNT:
        return {
          ...state, isSaving: true, saveFailed: undefined, saveSuccess: undefined,
        };

      case SAVE_LEAD_COUNT_FAIL:
        return {
          ...state, isSaving: false, saveFailed: true, saveSuccess: false,
        };

      case SAVE_LEAD_COUNT_SUCCESS: {
        return {
          ...state,
          data: action.leadCount,
          isSaving: false,
          saveFailed: false,
          saveSuccess: true,
        };
      }

      case UPDATE_LEAD_COUNT:
        return { ...state, data: { ...state.data,  value: action.value } };

      default:
        return state;
    }
  };

  export default leadCountReducer;
