import React from 'react';
import { HashLink } from 'react-router-hash-link';

const CallToActionButton = ({ buttonLabel }) => (
  <div className="call-to-action-button m-auto">
    <HashLink smooth to="/#top-of-page">
      {buttonLabel}
    </HashLink>
  </div>
);

export default CallToActionButton;
