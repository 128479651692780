import { Link } from 'react-router-dom';
import React from 'react';
import CreditGenieLogo from '../../../assets/logos/creditgenie_logo_blue.svg';

const Header = () => (
  <nav className="navigation bg-white py-4 z-10">
    <div className="shadow-lg pb-3">
      <div className="container items-center justify-between m-auto">
        <div className="logo items-center text-white m-auto px-2 xl:px-0">
          <Link to="/">
            <img className="h-8 sm:mr-2 m-auto sm:inline-block block" src={CreditGenieLogo} alt="Credit Genie Logo Blue" />
          </Link>
        </div>
      </div>
    </div>
  </nav>
);

export default Header;
