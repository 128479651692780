import { API } from 'aws-amplify';
import { createLeadCount, updateLeadCount } from '../../graphql/mutations';
import { listLeadCounts } from '../../graphql/queries';

export const GET_LEAD_COUNT = 'GET_LEAD_COUNT';
export const GET_LEAD_COUNT_FAIL = 'GET_LEAD_COUNT_FAIL';
export const GET_LEAD_COUNT_SUCCESS = 'GET_LEAD_COUNT_SUCCESS';
export const SAVE_LEAD_COUNT = 'SAVE_LEAD_COUNT';
export const SAVE_LEAD_COUNT_FAIL = 'SAVE_LEAD_COUNT_FAIL';
export const SAVE_LEAD_COUNT_SUCCESS = 'SAVE_LEAD_COUNT_SUCCESS';
export const UPDATE_LEAD_COUNT = 'UPDATE_LEAD_COUNT';

const getLeadCount = () => async (dispatch) => {
  dispatch({ type: GET_LEAD_COUNT });

  try {
    const leadCountsResponse = await API.graphql({
      query: listLeadCounts,
      variables: {limit: 1000},
      authMode: 'API_KEY',
    });

    const leadCount = leadCountsResponse.data.listLeadCounts.items[0];

    dispatch({ leadCount, type: GET_LEAD_COUNT_SUCCESS });

  } catch (getLeadCountError) {
    console.log('getLeadCountError:', getLeadCountError);

    dispatch({ type: GET_LEAD_COUNT_FAIL });
  }
}

const saveLeadCount = () => async (dispatch, getState) => {
  dispatch({ type: SAVE_LEAD_COUNT });

  const state = getState();

  const leadCount = state.leadCount.data;

  const saveOperation = leadCount.id ? updateLeadCount : createLeadCount;

  try {
    const saveResponse = await API.graphql({
      query: saveOperation,
      variables: {
        input: leadCount,
      },
      authMode: 'API_KEY',
    });

    dispatch({ leadCount: { ...leadCount, id: leadCount.id || saveResponse.data.createLeadCount.id }, type: SAVE_LEAD_COUNT_SUCCESS })
  } catch (error) {
    console.log('saveLeadCountFailure was called with:', error);

    dispatch({ type: SAVE_LEAD_COUNT_FAIL });
  }
};

const updateLeadCountValue = ({ value }) => ({
  type: UPDATE_LEAD_COUNT,
  value,
})

export {
  getLeadCount,
  saveLeadCount,
  updateLeadCountValue,
};
