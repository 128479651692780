import { combineReducers } from 'redux';
import leadCaptureReducer from './leadCaptureReducer';
import videoReducer from './videoReducer';
import leadCountReducer from './leadCountReducer';

const combinedReducers = combineReducers({
  lead: leadCaptureReducer,
  video: videoReducer,
  leadCount: leadCountReducer,
});

export default combinedReducers;
