import React from 'react';
import customerPortalSummaryPhone from '../../../assets/images/customer-portal-summary_graphic.png';
import { HashLink } from 'react-router-hash-link';

const WhyCreditGenie = () => (
  <div className="w-full bg-white">
    <div className="container inner-width flex m-auto sm:py-20 py-10 pt-4 lg:px-0 px-4">
      <div className="w-1/3 sm:block hidden">
        <img src={customerPortalSummaryPhone} alt="Customer portal summary phone" />
      </div>
      <div className="sm:w-2/3 w-full sm:pl-6 pl-0 pt-5 sm:text-left text-center">
        <h2 className="font-bold">
          Why Credit Genie?
        </h2>
        <p className="py-4">
          Credit Genie started with the idea that a technology-driven solution was needed to help consumers who got in over their
          heads with personal debt … because let&apos;s face it … it&apos;s happened to all of us at one time or another!
        </p>
        <p className="pb-4">
          By using cutting-edge technology we are able to instantly assess your financial picture to determine what you are able
          to pay on a monthly basis to significantly reduce your personal debt within 3 - 5 years.
        </p>
        <p>
          By working directly with your lenders, we&apos;re able to negotiate lower interest rates or actual reduction in the amount
          you owe. Because we work directly with your lenders – we&apos;re able to offer this service FREE of charge to you.
        </p>
        <HashLink className="sm:float-left float-none color-blue underline font-bold pt-4" to="/faq#comparison" target="_blank">
          <span className="sm:text-left text-center m-auto">
            Compare with debt settlement companies &gt;
          </span>
        </HashLink>
      </div>
    </div>
  </div>
);

export default WhyCreditGenie;
