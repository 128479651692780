import React from 'react';
import { useSelector } from 'react-redux';
import { Header, Footer, LegalDisclosure, Loading } from '../components/common/index';
import { VideoModal } from '../components/LandingPage';

const SubPageLayout = ({ children }) => {
  const isSaving = useSelector((state) => state.lead.isSaving);
  const showVideo = useSelector((state) => state.video.showVideo);

  return (
    <div>
      {showVideo && <VideoModal />}
      {isSaving && <Loading message="Saving..."/>}
      <Header />
      {children}
      <LegalDisclosure />
      <Footer />
    </div>
  );
};

export default SubPageLayout;
