import React from 'react';
import logo from '../../../assets/logos/creditgenie_logo_KO.svg';
import { EmailCaptureForm } from '../../common';
import { useDispatch } from 'react-redux';
import PlayButton from '../../../assets/icons/play_icon.svg';
import { setShowVideo } from '../../../redux/actions/videoActions';

const Hero = () => {
  const dispatch = useDispatch();

  const onWatchTheVideoClick = () => {
    dispatch(setShowVideo({ value: true }));
  };

  return (
    <div className="hero bg-cover bg-center bg-no-repeat md:bg-top" id="top-of-page">
      <div className="w-full lg:w-1/2 m-auto">
        <div className="container sm:pt-32 pt-20 m-auto sm:px-0 px-2">
          <img className="block m-auto pb-5" src={logo} alt="Credit Genie Logo Knockout" />
          <h1 className="text-white text-center text-5xl md:text-6xl font-bold mt-8 sm:w-3/4 w-full m-auto">
            Need help with your credit card bills?
            <br />
            {' '}
          </h1>
          <h2 className="text-white pt-5">
            Our <span className="font-bold">FREE</span> service will help you lower your monthly credit card payments.
            It&apos;s fast and simple.
          </h2>
        </div>
        <div className="mt-12 text-white px-2">
          <span className="font-bold sm:text-2xl text-xl">Enter your email below to get started.</span>
          <EmailCaptureForm />
        </div>
        <div className="video text-white sm:mt-16 mt-8 sm:pb-0 pb-8" onClick={onWatchTheVideoClick} onKeyDown={onWatchTheVideoClick} role="button" tabIndex="0">
          <div className="inline-block">
            Watch the video
          </div>
          <img className="inline-block ml-2" src={PlayButton} alt="Play button" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
