import React from 'react';
import { EmailCaptureForm } from '../../common';
import phoneImage from '../../../assets/images/how-it-works-phone_graphic.png';

const AlternativeHero = () => (
  <div className="alternative-hero bg-white w-full" id="top-of-page">
    <div className="w-full m-auto">
      <div className="container inner-width sm:py-16 py-1 m-auto xl:px-0 px-2">
        <div className="sm:flex flex-none m-auto">
          <div>
            <h1 className="sm:text-left text-center font-bold sm:mt-8 mt-4">
              Need help with your credit card bills?
              <br />
              {' '}
            </h1>
            <h2 className="pt-5 sm:text-left text-center">
              Our <span className="font-bold">FREE</span> service will help you lower your monthly credit card payments.
              It&apos;s fast and simple.
            </h2>
            <div className="mt-8 sm:text-left text-center">
              <span className="font-bold text-lg">Enter your email below to get started.</span>
              <EmailCaptureForm />
              <div className="pt-8 sm:pb-0 pb-10">
                <a className="bbb-logo" href="https://www.bbb.org/us/pa/radnor/profile/legal-information-services/creditly-corporation-0241-236047315/#sealclick" target="_blank" rel="nofollow noopener noreferrer">
                  <img className="block sm:m-0 m-auto" src="https://seal-dc-easternpa.bbb.org/seals/blue-seal-120-61-bbb-236047315.png" alt="Creditly Corporation BBB Business Review" />
                </a>
              </div>
            </div>
          </div>
          <div className="w-full pl-16 lg:block hidden">
            <img src={phoneImage} alt="Offer presentment" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AlternativeHero;
