import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getLeadCount, saveLeadCount, updateLeadCountValue } from '../../../redux/actions/actions';
import { SubmitInput, Form } from '../../common';
import EmailAddressInput from '../../LandingPage/EmailAddressInput/EmailAddressInput';
import { useDispatch, useSelector } from 'react-redux';
import useForm from '../../../components/common/Form/useForm';
import validate from '../../../components/common/Form/validator';

const EmailCaptureForm = () => {
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.lead.errorMessage || 'There was a problem saving your information. Please try again.');
  const leadCountValue = useSelector((state) => state.leadCount.data.value);
  const saveLeadSucceeded = useSelector((state) => state.lead.saveSuccess === true);
  const saveFailed = useSelector((state) => state.lead.saveFailed === true);
  const history = useHistory();
  const {
    errors,
    onLeadChange,
    onLeadTextInputBlur,
    onSubmit,
  } = useForm({ validate });

  useEffect(() => {
    dispatch(getLeadCount());
  }, [dispatch]);

  useEffect(() => {
    if (saveLeadSucceeded) {
      const updatedLeadCountValue = leadCountValue  + 1;
      dispatch(updateLeadCountValue({ value: updatedLeadCountValue }))
      dispatch(saveLeadCount());
      history.push('/success');
    }
  }, [dispatch, history, leadCountValue, saveLeadSucceeded]);

  return (
    <div className="email-capture-form">
      <Form onSubmit={onSubmit}>
        <div className="form-width sm:flex flex-none pt-2">
          <div>
            <EmailAddressInput errors={errors} onBlur={onLeadTextInputBlur} onChange={onLeadChange} />
          </div>
          <div className="ml-0 sm:px-2 px-0">
            <SubmitInput text="Get Started" />
          </div>
        </div>
      </Form>
      {saveFailed && <p className="validation failure sm:w-1/2 w-full mt-2 mx-auto">{errorMessage}</p>}
      <div className="term-acceptance sm:pt-0 pt-4">
        By clicking <span className="font-bold">&quot;Get Started&quot;</span> I agree to the Credit Genie
        {' '}
        <Link className="color-blue font-bold" to="/terms-and-conditions" target="_blank" >
          Terms and conditions
        </Link>
        {' '}
        and
        {' '}
        <Link className="color-blue font-bold" to="/privacy-policy" target="_blank" >
          Privacy Policy
        </Link>
      </div>
    </div>
  );
};

export default EmailCaptureForm;


