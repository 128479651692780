
import { Link } from 'react-router-dom';
import React from 'react';
import CreditGenieLogo from '../../../assets/logos/creditgenie_logo_KO.svg';

const Footer = () => (
  <section className="footer py-8 bg-dark-blue text-white">
    <div className="container mx-auto mt-8 mb-8">
      <div className="ml-2 sm:ml-0 xl:px-0 px-2">
        <img src={CreditGenieLogo} alt="Credit Genie Logo" />
        <p className="pt-2">Creditly Corp. copyright &copy; 2020</p>
        <div className="border-b pb-3 w-full sm:w-1/4" />
        <ul>
        <li className="pt-2">
            <Link to="/faq" target="_blank">
              FAQ
            </Link>
          </li>
          <li className="pt-2">
            <Link to="/privacy-policy" target="_blank">
              Privacy Policy
            </Link>
          </li>
          <li className="pt-2">
            <Link to="/terms-and-conditions" target="_blank">
              Terms and Conditions
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export default Footer;
