export default function validate({
  regularExpressions, requiredValues, values,
}) {
  const errors = {};
  const invalid = 'invalid';
  const required = 'required';

  Object.keys(values).forEach((valueKey) => {
    if (requiredValues[valueKey] && !values[valueKey]) {
      errors[valueKey] = required;

      return;
    }

    if (regularExpressions[valueKey] && !(new RegExp(regularExpressions[valueKey]).test(values[valueKey]))) {
      errors[valueKey] = invalid;

      return;
    }
  });

  return errors;
}
